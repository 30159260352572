<template>
      <v-container>
    <v-card outlined>
      <v-card-text class="error-message-box" v-if="errorMessageStatus">
      {{errorMessage}}
      </v-card-text>
      <v-card-text class="message-box" v-if="messageStatus">
      {{message}}
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
           <v-card-title
            class="display-2 grey--text text--darken-3 font-weight-bold"
            >
                All Claims
            </v-card-title>
            <FilterPopUp v-if="myClaimFilterPopUp.filter" :filter-data="myClaimFilterPopUp" @myClaimFilterPopUp='getName' ></FilterPopUp>
            <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                class="elevation-1"
            >
                <template v-slot:top>
                  <v-row
                    no-gutters
                  >
                    <v-col
                    class="text-align-right"
                    cols="12">
                      <v-icon class="pa-4 font-weight-bold" size="30px" @click="openFilterPopUp">
                          mdi-filter-variant
                      </v-icon>
                    </v-col>
                  </v-row>
                        <v-dialog v-model="dialogApprove" max-width="500px">
                        <v-card>
                            <v-btn depressed
                            color="error"
                            class="float-right"
                            @click="closeApprove">X</v-btn>
                            <v-card-title class="text-h5 pt-6">Amount of AED {{ editedItem.cashbackAmount }} will be credited to this Applicant's Wallet for {{ editedItem.brand }} orderId - {{ editedItem.orderid }}</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            depressed
                            :disabled="confirmDisable"
                            color="primary"
                            @click="approveItemConfirm(item)">
                              <div class="confirmLoader" v-if="claimLoader"></div>
                              <div v-else>
                                Confirm
                              </div>
                            </v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialogValidate" max-width="500px">
                        <v-card>
                            <v-btn depressed
                            color="error"
                            class="float-right"
                            @click="closeValidate">X</v-btn>
                            <v-card-title class="text-h5 pt-6">Are you sure you want to validate this claim with claim id {{ editedItem.claimid }} </v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            depressed
                            :disabled="confirmDisable"
                            color="primary"
                            @click="validateItemConfirm(item)">
                              <div class="confirmLoader" v-if="claimLoader"></div>
                              <div v-else>
                                Confirm
                              </div>
                            </v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.actions="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        v-if="item.action == 'Approve'"
                        :disabled="item.validate == 'Validate'"
                        @click="approveItem(item)"
                    >
                        {{ item.action }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        v-else
                        disabled
                        @click="approveItem(item)"
                    >
                        {{ item.action }}
                    </v-btn>
                </template>
                <template v-slot:item.validate="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        v-if="item.validate == 'Validate' || item.validate == 'Revalidate'"
                        @click="validateItem(item)"
                    >
                        {{ item.validate }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        v-else
                        disabled
                        @click="validateItem(item)"
                    >
                        {{ item.validate }}
                    </v-btn>
                </template>
                <template  v-slot:item.Attachment="{ item }">
                <!-- <v-btn v-for="i in item.Attachment"  @click="downloadResource(i.file_link,i.file)"  color="primary" depressed  >Download File</v-btn> -->
                <a v-for="i in item.Attachment"  :href="i.file_link" target="_blank" class="btn" style="color:white;background-color: #007bff;" depressed  >Download</a>
                </template>
            </v-data-table>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>
<script>
import axios from "axios";
import FilterPopUp from "./FilterPopUp.vue"
export default({
    name: "MyClaims",
    components: {
      FilterPopUp
    },
    data() {
        return {
          claimLoader:false,
          confirmDisable:false,
          errorMessage:'',
          errorMessageStatus:false,
          message:'',
          messageStatus:false,
            item:'',
            myClaimFilterPopUp: {
              filter:false,
              search:'',
              brand:'',
              status:[],
              cause:'',
            },
            headers: [
          {
            text: 'S. No.',sortable: false,
            align: 'start',
            value: 'sino',
          },
          { text: 'Client Name', value: 'client_id' ,sortable: false},
          { text: 'Claim ID', value: 'claimid' ,sortable: false},
          { text: 'User Email', value: 'user_email',sortable: false },
          { text: 'Brand', value: 'brand',sortable: false },
          { text: 'Order ID', value: 'orderid',sortable: false },
          { text: 'Order Amount', value: 'order_amount' ,sortable: false},
          { text: 'Cashback Amount', value: 'cashbackAmount' ,sortable: false},
          { text: 'Status', value: 'status',sortable: false },
          { text: 'Cause', value: 'cause',sortable: false },
          { text: 'Action', value: 'actions', sortable: false },
          { text: 'Validate', value: 'validate', sortable: false },
          { text: 'Attachment', value: 'Attachment', sortable: false , width: '200px'  },
          { text: 'Claim created Date', value: 'created_at', sortable: false },
          { text: 'Last Validated Date', value: 'validate_date', sortable: false },
          { text: 'Approved Date', value: 'approved_date', sortable: false },
        ],
        desserts: [],
        dialog: false,
        dialogApprove: false,
        dialogValidate: false,
        editedIndex: -1,
        editedItem: {
            sino: 0,
            id: 0,
            claimid: 0,
            brand: 0,
            orderid: 0,
            cashbackAmount: 0,
            status: [],
            cause: 0,
            action: 0,
            validate: 0,
            Attachment: [],
        },
        defaultItem: {
            sino: 0,
            id: 0,
            claimid: 0,
            brand: 0,
            orderid: 0,
            cashbackAmount: 0,
            status: [],
            cause: 0,
            action: 0,
            validate: 0,
            Attachment: [],
        },
        }
    },
    watch: {
      dialogApprove (val) {
        val || this.closeApprove()
      },
      dialogValidate (val) {
        val || this.closeValidate()
      },
      errorMessageStatus(val){

      },
      messageStatus(){}
    },
      mounted() {
        this.getClaimData();
    },
    
    methods: {
      showConsole(){
        console.log(this.editedItem.Attachment,'edi')
      },
      getName(value) {
        this.myClaimFilterPopUp = value;
          console.log('asdf');
          console.log(this.myClaimFilterPopUp);
          this.getClaimData();
      },
      forceDownload(blob, filename) {
        var a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
      },

    // Current blob size limit is around 500MB for browsers
    downloadResource(url, filename) {
      if (!filename) filename = url.split('\\').pop().split('/').pop();
      // fetch(url, {
      //     headers: new Headers({
      //       // 'Origin': location.origin
      //       // 'Access-Control-Allow-Origin':'*'
      //     }),
      //     mode: 'no-cors',
      //     responseType:'blob'
          
      //   })
        // .then(response => response.blob())
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
         axios({
                    url: url, // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                    headers: {"Access-Control-Allow-Origin": "*",  'Content-Type': 'application/x-www-form-urlencoded'} 
                })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);

          fileLink.click();
          // console.log(blob,'blob')
          // let blobUrl = window.URL.createObjectURL(blob);
          // this.forceDownload(blobUrl, filename);
        })
        .catch(e => console.error(e));
    },
    downloadIt (urls,name) {
    	axios.get(urls, {responseType: 'blob'})
      .then((response) => {
  			const url = window.URL.createObjectURL(new Blob([response.data]));
  			const link = document.createElement('a');
  			link.href = url;
  			link.setAttribute('download', name);
  			document.body.appendChild(link);
  			link.click();
			})
    }
    ,
      openFilterPopUp(){
        this.myClaimFilterPopUp.filter = true;
      },
        async getClaimData(){
          var statusarray = []
          var statusarraydata = this.myClaimFilterPopUp.status
          statusarraydata.forEach((statusText)=>{
            statusarray.push(statusText == 'Pending' ? 0 : statusText == 'Approved' ? 2 : statusText == 'Rejected' ? 3 : '');
          })
          this.$store.dispatch("setLoader");
          await axios.get('all-claims',{
                          params: {
                            claim_order_id: this.myClaimFilterPopUp.search,
                            brand: this.myClaimFilterPopUp.brand,
                            status: statusarray,
                            cause: this.myClaimFilterPopUp.cause,
                            client_id: this.myClaimFilterPopUp.client,
                            // client_id:window.localStorage.CLIENT_ID
                          }
                        })
              .then(response => {
                console.log(response.data.data);
                if(response.data.status == true){
                  this.desserts = [];
                  var allClaims = response.data.data;
                  allClaims.forEach((claims,index)=>{
                    var attach = [];
                    for(const a of claims.attachment ){
                      attach.push(a.file);
                    }
                    // console.log(attach,'attach')
                  this.desserts.push(
                      {
                          sino: index+1,
                          id: claims.id,
                          client_id : claims.client_id,
                          claimid: claims.claim_id,
                          user_email : claims.user_email,
                          brand: claims.brand_name,
                          orderid: claims.order_id,
                          order_amount: claims.order_amount,
                          cashbackAmount: claims.currency + " " +claims.amount,
                          status: claims.status == 0 ? 'Pending' : claims.status == 1 ? 'Processing' : claims.status == 2 ? 'Approved' : 'Rejected',
                          cause: claims.cause,
                          action: (claims.status == 0 || claims.status == 1) ? 'Approve' : claims.status == 2 ? 'Claimed' : 'Rejected',
                          validate: claims.status == 0 ? 'Validate' : 'Revalidate',
                          //validate: 'Validate',
                          Attachment: claims.attachment,
                          created_at: claims.created_at,
                          validate_date: claims.validate_date,
                          approved_date: claims.approved_date
                      },
                  );
                  });
                }
                else{
                  
                }
              });
              this.$store.dispatch("hideLoader");
        },
        approveItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogApprove = true
        this.item = item;
      },

      validateItem (item){
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogValidate = true;
        this.item = item;
       
      },

      async approveItemConfirm (item) {
        this.claimLoader = true;
        this.confirmDisable = true;
        await axios.post('change-claim-status',{
            id:this.desserts[this.desserts.indexOf(item)].id,
        }).then(response => {
          if(response.status){
          this.desserts[this.desserts.indexOf(item)].action = "Claimed";
          this.desserts[this.desserts.indexOf(item)].status = "Approved";
          this.message = "Claim approved successfully";
            this.messageStatus = true;
            setTimeout(() =>this.messageStatus = false, 5000);
          }
           
        });
        // this.desserts.splice(this.editedIndex, 1)
        this.confirmDisable = false;
        this.claimLoader = false;
        this.closeApprove()
      },

      async validateItemConfirm(item){
        this.claimLoader = true;
        this.confirmDisable = true;
        await axios.post('validate-claim',{
          id:this.desserts[this.desserts.indexOf(item)].id,
        }).then(response => {
          // console.log(response);
          if(response.data.status){
              this.desserts[this.desserts.indexOf(item)].action = "Approve";
              this.desserts[this.desserts.indexOf(item)].status = "Processing";
              this.message = response.data.message;
              this.messageStatus = true;
              setTimeout(() =>this.messageStatus = false, 5000);
            }
          else{
            this.desserts[this.desserts.indexOf(item)].action = "Rejected";
            this.desserts[this.desserts.indexOf(item)].status = "Rejected";
            this.errorMessage = response.data.error_msg;
            this.errorMessageStatus = true;
              setTimeout(() =>this.errorMessageStatus = false, 5000);
            }
            this.desserts[this.desserts.indexOf(item)].validate = "Validated";
        });
        this.getClaimData()
        this.confirmDisable = false;
        this.claimLoader = false;
        this.closeValidate()
        // this.hideMessages()
      },

      closeApprove () {
        this.dialogApprove = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1;
          this.item = '';

        })
      },
      hideMessages(){
        setTimeout(function() {
          console.log('asdffgghhj')
            this.messageStatus = false;
            this.errorMessageStatus = false;
          console.log('asdffgghhj')
          console.log(this.errorMessageStatus)
          console.log(this.messageStatus)
          }, 5000);
      },

      closeValidate () {
        this.dialogValidate = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1;
          this.item = '';
        })
      },
    },
})
</script>
<style>
.container{
  max-width: 1160px !important;
}
.text-align-right{
  text-align: right;
}
</style>
