<template>
  <v-app class="bg">
    
    <Header v-if="authRoutes()" />

    <v-main class="bg">
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
    
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div class="col-md-6 d-flex align-items-center">
        <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
        </a>
        <span class="mb-3 mb-md-0">Got a question or ran into any issues? Kindly contact us via WhatsApp at <a href="https://wa.me/+971559907708">+971 559907708</a></span>
      </div>
    </footer>

  </v-app>
  
</template>


<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",

  components: {
    Header,
  },
  methods: {
    authRoutes() {
      if (this.$route.path == '/signIn') return false;
      if (this.$route.path == '/signUp') return false;
      return true;
    }
  },
   cache: false,
  data: () => ({
    //
  }),
  created() {
    
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #fdf8f8 !important;
}
</style>
