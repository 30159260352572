<template>
<v-snackbar
          v-model="snackbar1"
          :multi-line="multiLine"
        >
          {{ text1 }}
          <!-- You need to reach minimum AED {{minimumWalletAmount}} to make a withdrawal. -->

          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
</template>
<script>

export default {
    name:'ErrorMessage',
    props:[
      'snackbar',
      'text'
    ],
    created(){
      this.snackbar1 = this.snackbar;
      this.text1 = this.text;
    },
    data: ()=>({
     multiLine: true,
     snackbar1: false,
     text1: `I'm a multi-line snackbar.`,
    })
}
</script>
