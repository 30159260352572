<template>
    <b-modal
        id="translate-modal"
        size="xs"
        title="Translate site"
        hide-backdrop
        scrollable
    ><Translator :countries="countries" @on-country-click="hideModal"
    /></b-modal>
</template>




<script>
import { Translator } from 'vue-google-translate';

export default {
  components: {
    Translator
  },
  data: () => ({
    countries: [
        {
          code: 'en|ar',
          title: 'Arabic',
        },
        {
          code: 'en|en',
          title: 'English',
        }
      ],
  }),
  created(){
  },
  methods: {
    hideModal() {
        this.$bvModal.hide("translate-modal");
    },
  },
};

</script>