import JwtService from "@/core/services/jwt.service";
import Api from '@/plugins/api.service.js';
import Vue from 'vue';
import router from '@/router.js';
import axios from "axios";


function removeUser() {
    window.localStorage.removeItem('USER_DATA');
    window.localStorage.removeItem('CLIENT_ID');
}


const state = {
    user: {},
    isAuthenticated: !!JwtService.getToken(),
    loading: false,
    updateProfileApi: "",
    role: !!JwtService.getRole(),
    errorSignup: false,
    errorMessageSignup: "",
    errorSignin: false,
    errorMessageSignin: "",
    walletAmount: null,
    minimumWalletAmount:0,
    amount_i_have_withdrawal:0,
    amount_i_can_withdrawal:0,
    claim_request_pending:0,
    total_claim:0,
    withdrawal_request_pending:0,
    total_withdrawal:0,
};
const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    user(state) {
        return state.user;
    },
    role(state) {
        return state.role;
    },
    errorSignup(state) {
        return state.errorSignup;
    },
    errorMessageSignup(state) {
        return state.errorMessageSignup;
    },
    errorSignin(state) {
        return state.errorSignin;
    },
    errorMessageSignin(state) {
        return state.errorMessageSignin;
    },
    walletAmount(state) {
        return state.walletAmount;
    },
    minimumWalletAmount(state) {
        return state.minimumWalletAmount;
    },
    amount_i_have_withdrawal(state) {
        return state.amount_i_have_withdrawal;
    },
    amount_i_can_withdrawal(state) {
        return state.amount_i_can_withdrawal;
    },
    claim_request_pending(state) {
        return state.claim_request_pending;
    },
    total_claim(state) {
        return state.total_claim;
    },
    withdrawal_request_pending(state) {
        return state.withdrawal_request_pending;
    },
    total_withdrawal(state) {
        return state.total_withdrawal;
    },
}

const actions = {
    hideLoader(){
        document.getElementById("loaderContainer").style.display = 'none';
    },
    setLoader(){
        document.getElementById("loaderContainer").style.display = 'block';
    },

    async getUser({ commit }) {
        commit('setLoader', true);
        await axios.get('me')
            .then(response => {
                commit('setLoader', false);
                if (response && response.data) {
                    commit('setUser', response.data);
                }
            })
            .catch(() => { commit('setLoader', false); })
    },

    async login({ commit }, payload) {
        console.log(payload)
        commit('setLoader', true);
        commit('errorSignin', false);
        await axios.post('login', payload)
            .then(response => {
                commit('setLoader', false);
                if (response && response.data && response.data.data && response.data.data.token && response.data.data.user.role) {
                    console.log(response);
                    JwtService.saveToken(response.data.data.token);
                    JwtService.saveRole(response.data.data.user.role);
                    JwtService.saveClientId(response.data.data.user.client_id);
                    // Save user in storage
                    commit('setUser', payload);

                    commit('authenticate', true);
                    if(response.data.data.user.role == 2){
                        router.push('my-claims');
                    }
                    else{
                        router.push('/');
                    }
                }
            })
            .catch((error) => {
                commit('setLoader', false);
                commit('errorSignin', true);
                // commit('errorMessage', error.response.data.errors[0]);
                console.log(typeof error.response.data.errors);
                if(typeof error.response.data.errors !== 'undefined'){
                    commit('errorMessageSignin', error.response.data.errors[0]);
                }
                else{
                    commit('errorMessageSignin', error.response.data.message);
                }
                console.log(error.response.data.message);
            })
    },
    async updateUser({ commit }, payload) {
        console.log(payload)
        commit('setLoader', true);
        await axios.post('profile-update', payload)
            .then(response => {
                commit('setLoader', false);
                // console.log(response)
                if(response.data.status=="Error"){
                    commit('setupdateProfileApi', "error");
                }else{
                    commit('setupdateProfileApi', "success");
                }
                setTimeout(function(){
                    commit('setupdateProfileApi', "");

                },3000)

                
            })
            .catch(() => { commit('setLoader', false); })
    },

    async register({ commit }, payload) {
        commit('setLoader', true);
        await axios.post('register', payload)
            .then(response => {
                commit('setLoader', false);
                if (response && response.data && response.data.data && response.data.data.token && response.data.data.user.role) {
                    JwtService.saveToken(response.data.data.token)
                    JwtService.saveRole(response.data.data.user.role);

                    // Save user in storage
                    commit('setUser', payload);

                    commit('authenticate', true);
                    if(response.data.data.user.role == 2){
                        router.push('my-claims');
                    }
                    else{
                        router.push('/');
                    }
                }
            })
            .catch((error) => {
                commit('setLoader', false);
                commit('errorSignup', true);
                if(typeof error.response.data.errors !== 'undefined'){
                    commit('errorMessageSignup', error.response.data.errors['email'][0]);
                }
                else{
                    commit('errorMessageSignup', error.response.data.message);
                }
                // commit('errorMessage', error.response.data.message);
            })
    },

    clearErrors({ commit }){
        commit('setLoader', false);
        commit('errorSignin', false);
        commit('errorSignup', false);
    },

    async logout({ commit }, payload) {
        await JwtService.destroyToken();
        commit('authenticate', false);
        // remove user from storage.
        removeUser();
        router.push('/signIn')
    },

    async fetchWalletAmount({ commit }){
        commit('setLoader', true);
        await axios.get('wallet-amount')
          .then(response => {
            commit('setLoader', false);
            console.log(response.data.data.wallet_amount);
            if(response.status){
                commit('walletAmount', response.data.data.wallet_amount);
                commit('minimumWalletAmount', response.data.data.minimum_withdrawal_amount);
                commit('amount_i_have_withdrawal', response.data.data.amount_i_have_withdrawal);
                commit('amount_i_can_withdrawal', response.data.data.amount_i_can_withdrawal);
                commit('claim_request_pending', response.data.data.claim_request_pending);
                commit('total_claim', response.data.data.total_claim);
                commit('withdrawal_request_pending', response.data.data.withdrawal_request_pending);
                commit('total_withdrawal', response.data.data.total_withdrawal);
            }
        })
      }
}

const mutations = {
    setUser(state, payload) {
        window.localStorage.setItem('USER_DATA', JSON.stringify(payload));
        state.user = payload;
    },
    authenticate(state, payload) {
        state.isAuthenticated = payload;
    },
    setGoogleSSO(state, payload) {
        state.googleSSO = payload;
    },
    setLoader(state, val) {
        state.loading = val;
    },
    setupdateProfileApi(state, val) {
        state.updateProfileApi = val;
    },
    role(state, val) {
        state.role = val;
    },
    errorSignup(state, val) {
        state.errorSignup = val;
    },
    errorMessageSignup(state, val) {
        state.errorMessageSignup = val;
    },
    errorSignin(state, val) {
        state.errorSignin = val;
    },
    errorMessageSignin(state, val) {
        state.errorMessageSignin = val;
    },
    walletAmount(state, val) {
        state.walletAmount = val;
    },
    minimumWalletAmount(state, val) {
        state.minimumWalletAmount = val;
    },
    amount_i_have_withdrawal(state, val) {
        state.amount_i_have_withdrawal = val;
    },
    amount_i_can_withdrawal(state, val) {
        state.amount_i_can_withdrawal = val;
    },
    claim_request_pending(state, val) {
        state.claim_request_pending = val;
    },
    total_claim(state, val) {
        state.total_claim = val;
    },
    withdrawal_request_pending(state, val) {
        state.withdrawal_request_pending = val;
    },
    total_withdrawal(state, val) {
        state.total_withdrawal = val;
    }

};

export default {
    state,
    actions,
    mutations,
    getters,
};
