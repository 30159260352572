<template>
    <v-toolbar
        flat
    >
        <v-dialog persistent v-model="dialogFilter" max-width="500px">
            <v-card>
                <v-btn depressed
                color="error"
                class="float-right ma-3"
                @click="closeApprove">X</v-btn>
                <v-card-title>
                    Filters
                </v-card-title>
                
                <v-container class="pa-6">

                    <v-text-field
                    v-model="filters.search"
                    label="Search by Claim ID or Order ID"
                    outlined
                    ></v-text-field>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Client
                    </v-list-item-title>
                    <v-select
                    label="Select client"
                    v-model="filters.client"
                    item-text="name"
                    item-value="id"
                    :items="allClients"
                    outlined
                    ></v-select>
                    
                    <v-list-item-title class="pb-2 font-weight-bold">
                        Brand
                    </v-list-item-title>
                    <v-select
                    label="Select Brand"
                    v-model="filters.brand"
                    item-text="name"
                    item-value="id"
                    :items="allBrands"
                    outlined
                    ></v-select>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Status
                    </v-list-item-title>
                    <v-row class="pb-6">
                        <v-col
                        v-for="action in actions"
                        :key="action">
                        <v-btn 
                        @click="statusFilter(action)"
                        :style="[filters.status.includes(action) ? {'background':'#6993ff'} : {'background': '#FFF'}]">
                            {{action}}
                        </v-btn>
                        </v-col>
                    </v-row>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Cause for Rejection
                    </v-list-item-title>
                    <v-select
                    :items="allCause"
                    label="Select Cause"
                    item-text="cause"
                    item-value="cause_id"
                    v-model="filters.cause"
                    outlined
                    ></v-select>

                    <v-row class="pb-6">
                        <v-col
                        cols="4"
                        sm="4"
                        class="text-right">
                            <v-btn @click="applyFilter">
                                Apply
                            </v-btn>
                        </v-col>

                        <v-col
                        cols="4"
                        sm="4"
                        class="text-left">
                            <v-btn @click="clearFilter">
                                clear
                            </v-btn>
                        </v-col>
                         <v-col
                        cols="4"
                        sm="4"
                        v-if="filters.search!='' || filters.brand!='' || filters.cause!='' || filters.status.length >0"
                        class="text-left">
                            <v-btn @click="clearFilterAll">
                                clear All
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-container>
            </v-card>
        </v-dialog>
    </v-toolbar>
</template>
<script>
import axios from "axios";


    export default({
        name: "FilterPopUp",
        props:[
            'myClaimFilterPopUp'
            , 'filterData'
        ],
        data() {
            return {
                filters:this.filterData,
                dialogFilter:true,
                allBrands: [],
                allClients: [],
                allCause: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                actions: ['Pending','Rejected','Approved'],
            }
        },
         mounted(){
                this.brandsData();
                this.clientsData();
                this.causeData();
            },
        methods: {
             clearFilterAll(){
                this.filters.search = '';
                this.filters.brand = '';
                this.filters.client = '';
                this.filters.status = [];
                this.filters.cause = '';
                this.applyFilter();
            },
            clearFilter(){
                this.filters.search = '';
                this.filters.brand = '';
                this.filters.client = '';
                this.filters.status = [];
                this.filters.cause = '';
            },
            applyFilter(){
                console.log(this.search + '-' + this.client + '-' + this.brand + '-' +this.status);
                this.closeApprove();
            },
            statusFilter(status){
                console.log(this.filters.status.lastIndexOf(status));
                if(this.filters.status.lastIndexOf(status) == -1){
                    this.filters.status.push(status);
                }
                else{
                    this.filters.status.splice(this.filters.status.lastIndexOf(status), 1)
                }
            },
            closeApprove () {
                this.filters.filter = false;
                this.$emit('myClaimFilterPopUp', this.filters);
            },
            async brandsData(){
                await axios.get('brand/index')
                .then(response => {
                    if(response.status){
                    this.allBrands = response.data.data;
                    }
                })
            },
            async clientsData(){
                await axios.get('client/index')
                .then(response => {
                    if(response.status){
                    this.allClients = response.data.data;
                    }
                })
            },

             async causeData(){
                await axios.get('all-cause')
                .then(response => {
                    if(response.status){
                    this.allCause = response.data.data;
                    }
                })
            },
        },
    })
</script>