<template>
      <v-container>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
           <v-card-title
            class="display-2 grey--text text--darken-3 font-weight-bold"
            >
                All Brands
            </v-card-title>
            <BrandPopUp v-if="myBrandPopUp.filter" :editValue="editValue" @myBrandPopUp='getName'/>
            <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                class="elevation-1"
            >
                <template v-slot:top>
                  <v-row no-gutters class="custom-row">
                    <v-col class="col-10 mt-2">
                      <input type="text" v-model="search" class="form-control float-right search-input" placeholder="Search Brands..." />
                    </v-col>
                    <v-col class="col-2 mt-2">
                      <v-btn class="font-weight-bold ml-5" @click="openFilterPopUp(undefined)">Add Brand</v-btn>
                    </v-col>
                  </v-row>
                        <v-dialog v-model="dialogApprove" max-width="500px">
                        <v-card>
                            <v-btn depressed
                            color="error"
                            class="float-right"
                            @click="closeApprove">X</v-btn>
                            <v-card-title class="text-h5 pt-6">Do you want to delete {{ editedBrand.name }}</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            depressed
                            color="primary"
                            @click="approveItemConfirm(editedBrand.id,editedBrand.sino)">Confirm</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </template>
                <template v-slot:item.edit="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        @click="openFilterPopUp(item.id)"
                    >
                        {{ item.edit }}
                    </v-btn>
                    </template>
                    <template v-slot:item.delete="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        v-if="item.delete == 'Delete'"
                        @click="approveItem(item)"
                    >
                        {{ item.delete }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        v-else
                        disabled
                        @click="approveItem(item)"
                    >
                        {{ item.delete }}
                    </v-btn>
                </template> 
            </v-data-table>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>
<script>
import axios from "axios";
import BrandPopUp from "./BrandPopUp.vue"
export default({
    name: "MyBrands",
    components: {
      BrandPopUp
    },
    data() {
        return {
            item:'',
            search:'',
            myBrandPopUp: {
                    filter:false,
                    name:'',
                    percentage_cashback:'',
                    commission_cashback:'',
            },
            headers: [
          {
            text: 'S. No.',
            align: 'start',
            value: 'sino',
          },
          { text: 'Brand', value: 'brand' },
          { text: 'Commission Percentage', value: 'commission_cashback' },
          { text: 'Percentage Cashback', value: 'percentage_cashback' },
          { text: 'User Cashback Percentage', value: 'user_cashback_percentage' },
          { text: 'Number of approved claims', value: 'total_claims' },
          { text: 'Total cashback amount (AED)', value: 'total_cashback_amount' },
          { text: 'Total order amount (AED)', value: 'total_order_amount' },
          { text: 'Edit', value: 'edit', sortable: false },
          { text: 'Delete', value: 'delete', sortable: false },
        ],
        desserts: [],
        dialog: false,
        editValue:undefined,
        dialogApprove: false,
        editedIndex: -1,
        editedBrand: {
            sino: 0,
            id: 0,
            brand: 0,
            percentage_cashback: 0,
            commission_cashback: 0,
            delete: 0,
        },
        defaultBrand: {
            sino: 0,
            id: 0,
            brand: 0,
            percentage_cashback: 0,
            commission_cashback: 0,
            delete: 0,
        },
        }
    },
    watch: {
      dialogApprove (val) {
        val || this.closeApprove()
      },
      search() {
          this.getResults();
      }
    },
    created() {
        this.getClaimData();
    },
    methods: {
      getName(value, editValue) {
        this.editValue = editValue
        this.myBrandPopUp = value;
          if(this.editValue !== undefined){
            this.updateBrand();
          }else{
          this.addBrand();
          }
      },
      getResults() {
        axios.get('/brand/index', { params: { search: this.search } })
          .then(response => {
            if(response.data.status == true){
              this.desserts = [];
              var allBrands = response.data.data;
              allBrands.forEach((brand,index)=>{
                this.desserts.push(
                  {
                    sino: index+1,
                    id: brand.id,
                    brand: brand.name,
                    percentage_cashback: brand.percentage_cashback,
                    commission_cashback: brand.commission_cashback,
                    user_cashback_percentage: brand.user_cashback_percentage,
                    total_claims: brand.total_claims,
                    total_cashback_amount: brand.total_cashback_amount,
                    total_order_amount: brand.total_order_amount,
                    delete: 'Delete',
                    edit: 'Edit',
                  },
                );
              });
            }
          })
        .catch(error => {});
      },
      openFilterPopUp(id){
        this.editValue = id
        this.myBrandPopUp.filter = true;
      },
        async getClaimData(){
          await axios.get(`brand/index?client_id=${window.localStorage.CLIENT_ID}`)
              .then(response => {
                if(response.data.status == true){
                  this.desserts = [];
                  var allBrands = response.data.data;
                  allBrands.forEach((brand,index)=>{
                  this.desserts.push(
                      {
                          sino: index+1,
                          id: brand.id,
                          brand: brand.name,
                          percentage_cashback: brand.percentage_cashback,
                          commission_cashback: brand.commission_cashback,
                          user_cashback_percentage: brand.user_cashback_percentage,
                          total_claims: brand.total_claims,
                          total_cashback_amount: brand.total_cashback_amount,
                          total_order_amount: brand.total_order_amount,
                          delete: 'Delete',
                          edit: 'Edit',
                      },
                  );
                  });
                }
                else{
                  
                }
              });
        }, 
        async updateBrand(){
          await axios.post('brand/update',{
                        id: this.editValue,
                        client_id: this.myBrandPopUp.client_id, 
                        name: this.myBrandPopUp.name,
                        percentage_cashback: this.myBrandPopUp.percentage_cashback,
                        commission_cashback: this.myBrandPopUp.commission_cashback,
                    })
              .then(response => {
                this.getClaimData();
              });
        },
        async addBrand(){
          await axios.post('brand/store',{
                        client_id: this.myBrandPopUp.client_id,
                        name: this.myBrandPopUp.name,
                        percentage_cashback: this.myBrandPopUp.percentage_cashback,
                        commission_cashback: this.myBrandPopUp.commission_cashback,
                    })
              .then(response => {
                this.getClaimData();
              });
        },
        approveItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedBrand = Object.assign({}, item)
        this.dialogApprove = true
        this.item = item;
      },

      async approveItemConfirm (id,sino) {
        await axios.post('brand/delete',{
                        id: id,
                    })
              .then(response => {
                this.getClaimData();
              });
        // this.desserts.splice(this.editedIndex, 1)
        this.closeApprove()
      },


      closeApprove () {
        this.dialogApprove = false
        this.$nextTick(() => {
          this.editedBrand = Object.assign({}, this.defaultBrand)
          this.editedIndex = -1;
          this.item = '';

        })
      },
    },
})
</script>
<style>
.text-align-right{
  text-align: right;
}
.custom-row {
  justify-content: flex-end;
}

.search-input {
  width: 30% !important;
}
</style>
