<template>
  <div>
    <div id="loaderContainer" >
    <div class="loader-temp"></div>
</div>
    <v-app-bar class="elevation-0" app color="primary" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <router-link to="/">
          <div class="d-flex align-center pointer" >
            <v-img
            alt="Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="40"
          />
  
          <h1 class="custom_h1" style="color:white">Cashback Program</h1>
          </div>
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <b-link class="custom_css_link" href="/how-it-works" style="color : #fff;">
        <span class="mr-2">How it works ?</span>
      </b-link>

      <b-button
          class="mx-1 text-white custom_translate"
          variant="primary"
          size="sm"
          v-b-modal.translate-modal
        >
          Translate
        </b-button>

      <v-btn text @click="logout">
        <span class="mr-2">Sign Out</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <translate-modal></translate-modal>

    <v-navigation-drawer v-model="drawer" absolute temporary style="position:fixed">
      <v-list-item to="/" v-if="role == 3">
        <v-list-item-icon>
          <v-icon color="primary">mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <!-- <v-list-item to="/">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div class="d-flex align-center">
              <v-img
                alt="Logo"
                class="shrink mr-2"
                contain
                src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
                transition="scale-transition"
                width="40"
              />

              <h5>Home</h5>
            </div>
          </v-list-item-title>
           <v-list-item-subtitle> subtext </v-list-item-subtitle> 
        </v-list-item-content>
      </v-list-item> -->

      <v-divider v-if="role == 3"></v-divider>

      <v-list nav>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item
            v-for="(nav, index) in navigation"
            :key="`${index}-nav`"
            :to="nav.path"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ nav.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ nav.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import { mapGetters } from 'vuex';
import TranslateModal  from "@/components/TranslateModal.vue";


export default {
  components: {
    TranslateModal
  },
  data: () => ({
    drawer: false,
    group: null,
    role:'',
    navigationFt:[
      {
        name: "All Claims",
        icon: "mdi-ticket-account",
        path: "/my-claims",
      },
      {
        name: "All Withdrawals",
        icon: "mdi-wallet",
        path: "/withdrawal",
      },
      {
        name: "Brands",
        icon: "mdi-credit-card",
        path: "/brands",
      },
      {
        name: "Settings",
        icon: "mdi-wallet",
        path: "/withdrawal",
      },
    ],
    navigationIt:[
      
      {
        name: "My Claims",
        icon: "mdi-ticket-account",
        path: "/applicants-my-claims",
      },
      {
        name: "My Wallet",
        icon: "mdi-wallet",
        path: "/wallet",
      },
      {
        name: "My Account",
        icon: "mdi-account",
        path: "/account",
      },
      {
        name: "Search orders",
        icon: "mdi-tag-search",
        path: "/orders",
      },
      {
        name: "How it works",
        icon: "mdi-help",
        path: "/how-it-works",
      },
    ],
    navigation: [],
  }),
  created(){
    this.role = window.localStorage.getItem('CB_ROLE');
      console.log(this.role);
      console.log('asdf');
    if(this.role == 2){
      this.navigation = this.navigationFt;
    }
    else{
      this.navigation = this.navigationIt;
    }
  },
  methods: {
    logout() {
      // Logout from Google Auth if logged In.
      if (this.$gAuth && this.$gAuth.isAuthorized) {
        this.$gAuth
          .signOut()
          .then(() => {
            //on success do something
            // this.isSignIn = this.$gAuth.isAuthorized;
            console.log("User signed out. isAutorized", this.$gAuth.isAuthorized);
          })
          .catch((error) => {
            //on fail do something
            console.log("Error", error);
            throw error;
          });
      }

      this.$store.dispatch("logout");
    },
    openmodal() {
      console.log("hello")
    },
  },
};
</script>

<style>
</style>