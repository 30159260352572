import Vue from 'vue'
import Router from 'vue-router'

import Home from './components/applicants/Home.vue';
import MyClaimsVue from './components/FTmembers/claims/MyClaims.vue';
import WithdrawalVue from './components/FTmembers/withdrawal/Withdrawal.vue';
import MyBrands from './components/brand/MyBrands.vue';

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/auth',
        name: 'Auth Route',
        redirect: '/SignIn',
        component: () => import('@/views/pages/auth/index.vue'),
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                path: '/SignIn',
                name: 'SignIn',
                component: () => import('@/views/pages/auth/SignIn.vue'),
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: '/SignUp',
                name: 'SignUp',
                component: () => import('@/views/pages/auth/SignUp.vue'),
                meta: {
                    requiresAuth: false,
                },
            },
        ]
    },
    {
        path: '/applicants-my-claims',
        name: 'applicantsMyClaims',
        component: () => import('@/components/applicants/claims/MyClaims.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('@/components/applicants/myWallet/Wallet.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/account',
        name: 'MyAccount',
        component: () => import('@/views/pages/auth/account.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/new-request',
        name: 'newRequest',
        component: () => import('@/components/applicants/new_request.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/how-it-works',
        name: 'HowItWorks',
        component: () => import('@/components/applicants/how_it_works.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/edit-request/:id',
        name: 'editRequest',
        component: () => import('@/components/applicants/new_request.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/withdrawl-request',
        name: 'WithdrawlRequest',
        component: () => import('@/components/applicants/myWallet/WithdrawSuccess.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/components/applicants/orders.vue'),
        meta: {
            requiresAuth: true,
            role: 3,
        },
    },
    {
        path: '/my-claims',
        name: 'MyClaims',
        component: MyClaimsVue,
        meta: {
            requiresAuth: true,
            role: 2,
        },
    },
    {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: WithdrawalVue,
        meta: {
            requiresAuth: true,
            role: 2,
        },
    },
    {
        path: '/brands',
        name: 'brands',
        component: MyBrands,
        meta: {
            role: 2,
        },
    },
    {
        path: "*",
        redirect: "/",
    },

]

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})



