<template>
  <v-container class="pb-0">
    <v-card color="transparent" elevation="0">
      <v-card-text class="p-0">
        <v-snackbar
          v-model="snackbar"
          :multi-line="multiLine"
        >
          You need to reach minimum AED {{minimumWalletAmount}} to make a withdrawal.

          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-row class="d-flex align-center .justify-space-between mb-5">
          <v-col
          cols="12"
          md="4" 
          class="align-center">
            <h2 class="mb-3 wallet-heading-color">
              My Wallet
            </h2>
            <div class="wallet-amount">
              <div class="py-6">
                <div class="loader" v-if="$store.state.auth.loading"></div>
                <div v-else>
                  AED {{walletAmount}}
                </div>
              </div>
            </div>
            <div class="h4 pt-2 wallet-heading-color">
                Cashback ready to withdraw
            </div>
          </v-col>
          <v-col 
          cols="12"
          md="8"
          class="text-md-right">
          <!-- <div class="minimum-limit-error text-left" v-if="Withdrawalerror">
            You need to reach minimum AED {{minimumWalletAmount}} to make a withdrawal.
          </div> -->
            <v-btn class="m-2" @click="checkMinAmount" height="50" depressed :class="WithdrawalColor" :disabled="WithdrawalDisable || withdrawalLoader" rounded width="250">
              <div class="loader1" v-if="withdrawalLoader"></div>
              <div v-else>
                Withdraw from wallet
              </div>
            </v-btn>
            <v-btn class="m-2" @click="$router.push({ name: 'newRequest' })" height="50" depressed :class="claimCashbackColor" :disabled="claimCashbackDisable" rounded width="250">
              Claim new cashback
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
  </template>

  
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import ErrorMessage from "./../toastError/ErrorMessage.vue"

export default {
    name:'ItcannerHeader',
  data: () => ({
    // text:'You need to reach minimum AED {{minimumWalletAmount}} to make a withdrawal.',
    snackbar:false,
    multiLine:true,
    claimCashbackColor:'',
    WithdrawalColor:'',
    claimCashbackDisable:false,
    WithdrawalDisable:false,
    // Withdrawalerror:false,
    withdrawalAmount:200,
    withdrawalLoader:false,
    mainCards: [
      {
        name: 'withdrawn',
        text: 'Amount withdrawn so far',
        value: 0,
        color: 'primary',
        class: ''
      },
      {
        name: 'approvals',
        text: 'Requests pending for approval',
        value: 0,
        color: 'error',
        class: ''
      },
      {
        name: 'Withdrawl',
        text: 'Amount I can withdraw',
        value: 0,
        color: 'secondary',
        class: ''
      },
    ],
  }),
  computed: {
    ...mapGetters(["walletAmount","minimumWalletAmount","amount_i_have_withdrawal","amount_i_can_withdrawal","claim_request_pending"])
  },
  created() {
      this.claimCashbackColor = this.$route.name == 'newRequest' ? 'secondary' : 'primary';
      this.WithdrawalColor = this.$route.name == 'WithdrawlRequest' ? 'secondary' : 'primary';
      
      this.claimCashbackDisable = this.$route.name == 'newRequest' ? true : false;
      this.WithdrawalDisable = this.$route.name == 'WithdrawlRequest' ? true : false;
      // this.asignValues();
      // if(this.walletAmount == null || this.minimumWalletAmount == null){
      //   this.$store.dispatch("fetchWalletAmount");
      // }
  },
  beforeMount() {
    this.$store.dispatch("fetchWalletAmount");
    this.asignValues();
    // this.checkMinAmount();
  },
  methods:{
    asignValues(){
      this.mainCards[0].value = this.amount_i_have_withdrawal;
      this.mainCards[1].value = this.claim_request_pending;
      this.mainCards[2].value = this.amount_i_can_withdrawal;
    },
    checkMinAmount(){
      if(this.walletAmount >= this.minimumWalletAmount){
        this.withdrawalLoader = true;
         axios.post('withdrawal')
          .then(response => {
            console.log(response);
            if(response.data.status == true){
              this.$router.push({ name: 'WithdrawlRequest', params: {orderId: response.data.data.request_id} });
              this.$store.dispatch("fetchWalletAmount");
              this.withdrawalLoader = false;
            }
            else{
              // this.Withdrawalerror = true;
              this.withdrawalLoader = false;
            }
          });
      }
      else{
        this.snackbar = true;
        // this.Withdrawalerror = true;
        this.withdrawalLoader = false;
      }
    },
    fetchWalletAmount(){
        this.$store.dispatch("fetchWalletAmount");
    }
  }

};
</script>

<style>
.wallet-amount{
    width: 250px;
    background-color: #36cdc5 !important;
    border-radius: 10px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: white;
    border: 3px solid #3684cd;
}
.wallet-heading-color{
    color: #36cdc5 !important;
}
.minimum-limit-error{
    font-size: 17px;
    color: red;
    font-weight: bold;
}
</style>