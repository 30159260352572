<template>
    <v-toolbar
        flat
    >
        <v-dialog persistent v-model="dialogFilter" max-width="500px">
            <v-card>
                <v-btn depressed
                color="error"
                class="float-right ma-3"
                @click="closeApprove">X</v-btn>
                <v-card-title>
                    {{editValue == undefined ? 'Add Brand' : 'Edit Brand'}}
                </v-card-title>
                
                <v-container class="pa-6">

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Brand Name
                    </v-list-item-title>
                    <v-text-field
                    v-model="addBrand.name"
                    label="Brand Name"
                    outlined
                    ></v-text-field>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Commission Percentage
                    </v-list-item-title>
                    <v-text-field
                    v-model="addBrand.commission_cashback"
                    label="Commission Percentage"
                    outlined
                    ></v-text-field>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Percentage Cashback
                    </v-list-item-title>
                    <v-text-field
                    v-model="addBrand.percentage_cashback"
                    label="Percentage Cashback"
                    outlined
                    ></v-text-field>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Client
                    </v-list-item-title>
                    <v-select
                        v-model="client"
                        outlined
                        item-text="name"
                        item-value="id"
                        label="Client"  
                        :items="clientOptions"
                        @input="handelClient($event)"
                        >
                    </v-select>

                    <v-row class="pb-6">
                        <v-col
                        :cols="editValue == undefined ? '6' : '12'"
                        :sm="editValue == undefined ? '6' : '12'"
                        class="text-center">
                            <v-btn @click="applyFilter">
                               {{editValue == undefined ? 'Add' : 'Update'}}
                            </v-btn>
                        </v-col>

                        <v-col
                        v-if="editValue == undefined"
                        cols="6"
                        sm="6"
                        class="text-left">
                            <v-btn @click="clearFilter">
                                clear
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-container>
            </v-card>
        </v-dialog>
    </v-toolbar>
</template>
<script>

import axios from "axios";
    export default({
        name: "BrandPopUp",
        props:[
            'myBrandPopUp',
            'editValue'
        ],
        data() {
            return {
                addBrand:{
                    filter:true,
                    name:'',
                    percentage_cashback:'',
                    commission_cashback:'',
                    client_id:''
                },
                client:{},
                clientOptions:[],
                dialogFilter:true,
            }
        },
        async mounted(){
            await this.getAllClint()
            if(this.editValue !== undefined){
            await axios.get(`brand/view/${this.editValue}`)
              .then(response => {
                this.setEditItem(response.data.data)
              })
            }
        },
        methods: {
            async getAllClint(){
                await axios.get('/client/index')
                .then((res)=>{
                    this.clientOptions=res.data.data
                })
            },
            handelClient(value){
                this.addBrand.client_id=value
            },
            setEditItem(item){
                    this.addBrand.name = item.name,
                    this.addBrand.percentage_cashback = item.percentage_cashback,
                    this.addBrand.commission_cashback = item.commission_cashback
                    this.clientOptions.map((client)=>{
                        if(client.id == item.client_id){
                            this.client=client
                            this.addBrand.client_id=client.id
                        }
                    })
            },
            clearFilter(){
                this.addBrand.name = '';
                this.addBrand.percentage_cashback = '';
                this.addBrand.commission_cashback = '';
            },
            applyFilter(){
                this.closeApprove();
            },
            
            closeApprove () {
                this.addBrand.filter = false;
                this.$emit('myBrandPopUp', this.addBrand, this.editValue);
            },
        },
    })
</script>