import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import toast from './plugins/toast';
import router from './router.js'
import store from "@/store";
import './styles/app.scss'
import Api from '@/plugins/api.service.js'
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);


// Making it global
Vue.prototype.$api = Api;
Vue.config.productionTip = false

// import GAuth from 'vue-google-oauth2'
import axios from 'axios';
// const gauthOption = {
//   // clientId: '191122502483-pbt5iq57cd5b9blje1uum7v0a48bldqo.apps.googleusercontent.com', //old
//   // clientId: '557672581982-a10qjrucnl69t3uniajhkvm7ait2hchi.apps.googleusercontent.com', //new
//   clientId : '135626791749-5jrgb5k46ho9lmpug1n5bjkv6fgmjdr6.apps.googleusercontent.com',
//   scope: 'email',
//   fetch_basic_profile: true,
//   prompt: 'select_account',
//   // hosted_domain: 'itcan.ae'
// }
// Vue.use(GAuth, gauthOption)

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    if (store.state.auth.isAuthenticated) {
      // debugger
      if(to.meta.role == window.localStorage.getItem('CB_ROLE')){
        next();
      }
      else{
        if(window.localStorage.getItem('CB_ROLE') == 2){
          next('my-claims');
        }
        else{
          next('/');
        }
      }
    } else {
      next('/signIn')
    }
  } else {
    next()
  }
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

new Vue({
  toast,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
