<template>
    <v-toolbar
        flat
    >
        <v-dialog persistent v-model="dialogFilter" max-width="500px">
            <v-card>
                <v-btn depressed
                color="error"
                class="float-right ma-3"
                @click="closeApprove">X</v-btn>
                <v-card-title>
                    Filters
                </v-card-title>
                
                <v-container class="pa-6">

                    <v-text-field
                    v-model="filters.emailIdSearch"
                    label="Search by Itcan email ID"
                    outlined
                    ></v-text-field>
                    
                    <v-list-item-title class="pb-2 font-weight-bold">
                        Created At
                    </v-list-item-title>
                    <div class="row">
                        <div class="col-6">
                            From<br>
                            <!-- <b-form-datepicker
                            v-model="filters.createdatFrom" @input="dateChange('Create','createdatFrom','createdatTo')"
                        ></b-form-datepicker> -->
                            <v-text-field type="date"  @input="dateChange('Create','createdatFrom','createdatTo')" v-model="filters.createdatFrom">
                            </v-text-field>
                        </div>
                        
                        <div class="col-6">
                            To<br>
                            <!-- <b-form-datepicker
                            v-model="filters.createdatTo" @input="dateChange('Create','createdatTo','createdatFrom')"
                        ></b-form-datepicker> -->
                            <v-text-field type="date" @input="dateChange('Create','createdatTo','createdatFrom')" v-model="filters.createdatTo">
                            </v-text-field>
                        </div>
                    </div>
                    <v-list-item-title class="pb-2 font-weight-bold">
                        Updated At
                    </v-list-item-title>
                    <div class="row">
                        <div class="col-6">
                            From<br>
                            <!-- <b-form-datepicker
                            v-model="filters.updatedatFrom" @input="dateChange('Update','updatedatFrom','updatedatTo')"
                        ></b-form-datepicker> -->
                            <v-text-field type="date"  @input="dateChange('Update','updatedatFrom','updatedatTo')" v-model="filters.updatedatFrom">
                            </v-text-field>
                        </div>
                        
                        <div class="col-6">
                            To<br>
                            <!-- <b-form-datepicker
                            v-model="filters.updatedatTo" @input="dateChange('Update','updatedatTo','updatedatFrom')"
                        ></b-form-datepicker> -->
                            <v-text-field type="date" @input="dateChange('Update','updatedatTo','updatedatFrom')" v-model="filters.updatedatTo">
                            </v-text-field>
                        </div>
                    </div>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Status
                    </v-list-item-title>
                    <v-row class="pb-6 text-center">
                        <v-col
                        v-for="action in actions"
                        :key="action">
                        <v-btn 
                        @click="statusFilter(action)"
                        :style="[filters.status.includes(action) ? {'background':'#6993ff'} : {'background': '#FFF'}]">
                            {{action}}
                        </v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="pb-6">
                        <v-col
                        cols="6"
                        sm="6"
                        class="text-right">
                            <v-btn @click="applyFilter">
                                Apply
                            </v-btn>
                        </v-col>

                        <v-col
                        cols="6"
                        sm="6"
                        class="text-left">
                            <v-btn @click="clearFilter">
                                clear
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-container>
            </v-card>
        </v-dialog>
    </v-toolbar>
</template>
<script>
// import moment from 'moment';
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
    export default({
        name: "WithdrawalPopUp",
        props:[
            'WithdrawalFilterPopUp'
        ],
        data() {
            return {
                selectedRange: [null, null],
                 maxDate: new Date() ,
                filters:{
                    filter:true,
                    emailIdSearch:'',
                    createdatFrom:'',
                    createdatTo:'',
                    updatedatFrom:'',
                    updatedatTo:'',
                    status:[],
                },
                dialogFilter:true,
                actions: ['Pending','Processing','Completed'],
            }
        },
        methods: {
            
            myGreeting(){
                this.filters.createdatFrom = null
                    this.filters.createdatTo = null
                    clearTimeout(this.myTimeout);
            },
            myGreeting1(){
                   this.filters.updatedatFrom = null
                    this.filters.updatedatTo = null
                    clearTimeout(this.myTimeout1);
            },
            dateChange(value){
             if(value == 'Create'){
                console.log( new Date(this.filters.createdatTo),"hjugdsujdfgbv")
               if((new Date(this.filters.createdatFrom) > new Date(this.filters.createdatTo)) && (this.filters.createdatFrom !== null && this.filters.createdatFrom !== '' && this.filters.createdatTo !== null && this.filters.createdatTo !== '' )){
                   alert('From date should not be greater than To date')
                   this.myTimeout = setTimeout(this.myGreeting, 100);

               }
             }
             if(value == 'Update'){
               if((new Date(this.filters.updatedatFrom) > new Date(this.filters.updatedatTo) ) && (this.filters.updatedatFrom !== null && this.filters.updatedatFrom !== '' && this.filters.updatedatTo !== null && this.filters.updatedatTo !== '' )){
                   alert('From date should not be greater than To date')
                   this.myTimeout1 = setTimeout(this.myGreeting1, 100);
               }
             }
            },
            clearFilter(){
                this.filters.emailIdSearch = '';
                this.filters.createdatFrom = '';
                this.filters.createdatTo = '';
                this.filters.updatedatFrom = '';
                this.filters.updatedatTo = '';
                this.filters.status = [];
            },
            applyFilter(){
                console.log(this.filters.amountSearch + '-' + this.filters.createdatFrom + '-' +this.filters.updatedatFrom);
                this.closeApprove();
            },
            statusFilter(status){
                console.log(this.filters.status.lastIndexOf(status));
                if(this.filters.status.lastIndexOf(status) == -1){
                    this.filters.status.push(status);
                }
                else{
                    this.filters.status.splice(this.filters.status.lastIndexOf(status), 1)
                }
            },
            closeApprove () {
                this.filters.filter = false;
                this.$emit('WithdrawalFilterPopUp', this.filters);
            },
        },
    })
</script>