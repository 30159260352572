<template>
  <v-container>
    <ItcannerHeader/>
    <v-card color="transparent" elevation="0">
      <v-card-text>
        <!-- <div class="d-flex align-center justify-start mb-5">
          <div>

          </div>
        </div> -->

        <v-row>
          <v-col v-for="(details, index) in mainCards" :key="`$cards-${index}`">
            <v-card height="120" outlined class="rounded-lg primary white--text d-flex align-center place-center" :class="`${details.color} ${details.class}`">
              <h2 :class="$vuetify.breakpoint.mobile ? 'text-h4 pa-3' : 'display-3 pa-5'" class="font-weight-bold" >{{ details.name == 'approvals' ? '' : 'AED' }} {{ details.value }}</h2>
            </v-card>
            <div class="py-3 px-1">
              <h5 class="font-weight-bold my-2"
                >{{ details.text }}</h5
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined elevation="0" class="my-5">
      <v-card-title style="font-size:18px !important;"
        class="display-2 grey--text text--darken-3 font-weight-bold"
      >
        Featured Cashback Offers
      </v-card-title>
      <v-card-text>
        <v-list subheader>
          <template v-for="(offer, index) in offers">
            <!-- <v-divider :key="index"></v-divider> -->

            <v-list-item class="pointer outlined my-2 rounded-lg" :key="`${index}-offers`">
              <v-list-item-content>
                <v-list-item-title>
                  {{ offer.title }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <!-- <v-btn
                  rounded
                  :color="offer.active ? 'error accent-4' : 'grey'"
                  height="40"
                  depressed
                >
                  Coming Soon
                </v-btn> -->
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ItcannerHeader from "./ItcannerHeader.vue"

export default {
  components: {
    ItcannerHeader
  },
  data: () => ({
    mainCards: [
      {
        name: 'withdrawn',
        text: 'Amount withdrawn so far',
        value: 0,
        color: 'primary',
        class: ''
      },
      {
        name: 'approvals',
        text: 'Requests pending for approval',
        value: 0,
        color: 'error',
        class: ''
      },
      {
        name: 'Withdrawl',
        text: 'Amount I can withdraw',
        value: 0,
        color: 'secondary',
        class: ''
      },
    ],
    offers: [
      {
        // active: true,
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Get up to 1.5% Cashback on your Ounass orders",
      },
      {
        img: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Get up to 4% Cashback on your Nayomi orders",
      },
      {
        img: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Get up to 2.5% Cashback on your Boots orders",
      },
      {
        img: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Get up to 2.5% Cashback on your Riva orders",
      },
    ],
  }),
    computed: {
    ...mapGetters(["amount_i_have_withdrawal","amount_i_can_withdrawal","claim_request_pending"]),
  },
  created(){
    this.asignValues();
  },
  watch:{
    amount_i_have_withdrawal(){
      this.asignValues();
      },
  },
  methods:{
    asignValues(){
      this.mainCards[0].value = this.amount_i_have_withdrawal;
      this.mainCards[1].value = this.claim_request_pending;
      this.mainCards[2].value = this.amount_i_can_withdrawal;
      },
    }
};
</script>

<style>

</style>