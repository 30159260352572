<template>
      <v-container>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
           <v-card-title
            class="display-2 grey--text text--darken-3 font-weight-bold"
            >
                All Withdrawal Request
            </v-card-title>
            <WithdrawalPopUp v-if="WithdrawalFilterPopUp.filter" @WithdrawalFilterPopUp='getName'/>
            <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                class="elevation-1"
            >
                <template v-slot:top>
                  <v-row
                    no-gutters
                  >
                    <v-col
                    class="text-align-right"
                    cols="12">
                      <v-icon class="pa-4 font-weight-bold" size="30px" @click="openFilterPopUp">
                          mdi-filter-variant
                      </v-icon>
                    </v-col>
                  </v-row>
                        <v-dialog v-model="dialogApprove"
                         max-width="500px">
                        <v-card>
                            <v-btn depressed
                            color="error"
                            class="float-right"
                            @click="closeApprove">X</v-btn>
                            <v-card-title class="text-h5 pt-6">Amount of {{ editedItem.amount }} will be transferred to bank of user with User ID {{ editedItem.userid }}</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            class="mb-2"
                            depressed
                            color="primary"
                            @click="approveItemConfirm(item)">Confirm</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.actions="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        v-if="item.action == 'Update'"
                        @click="approveItem(item)"
                    >
                        {{ item.action }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        v-else
                        disabled
                        @click="approveItem(item)"
                    >
                        {{ item.action }}
                    </v-btn>
                </template>
            </v-data-table>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>
<script>
import axios from "axios";
import WithdrawalPopUp from "./WithdrawalPopUp.vue"
export default({
    name: "Withdrawal",
    components: {
      WithdrawalPopUp
    },
    data() {
        return {
            item:'',
            WithdrawalFilterPopUp: {
              filter:false,
              emailIdSearch:'',
              createdatFrom:'',
              createdatTo:'',
              updatedatFrom:'',
              updatedatTo:'',
              status:[],
            },
            headers: [
          {
            text: 'S. No.',
            align: 'start',
            value: 'sino', sortable: false
          },
          { text: 'Request ID', value: 'requestid', sortable: false },
          { text: 'User ID', value: 'userid', sortable: false },
          { text: 'Amount', value: 'amount', sortable: false },
          { text: 'Created At', value: 'createdat', sortable: false },
          { text: 'Updated At', value: 'updatedat', sortable: false },
          { text: 'Status', value: 'status', sortable: false },
          { text: 'Action', value: 'actions', sortable: false },
        ],
        desserts: [],
        dialog: false,
        dialogApprove: false,
        editedIndex: -1,
        editedItem: {
            sino: '',
            id: '',
            requestid: '',
            userid: '',
            amount: '',
            createdat: '',
            updatedat: '',
            status: [],
            action: '',
        },
        defaultItem: {
            sino: '',
            id: '',
            requestid: '',
            userid: '',
            amount: '',
            createdat: '',
            updatedat: '',
            status: [],
            action: '',
        },
        }
    },
    watch: {
      dialogApprove (val) {
        val || this.closeApprove()
      },
    },  
    created() {
        this.getWithdrawalData();
    },
    methods: {
      getName(value) {
        this.WithdrawalFilterPopUp = value;
          console.log(value);
          this.getWithdrawalData();
      },
      openFilterPopUp(){
        this.WithdrawalFilterPopUp.filter = true;
      },
        async getWithdrawalData(){
          var statusarray = []
          var statusarraydata = this.WithdrawalFilterPopUp.status
          statusarraydata.forEach((statusText)=>{
            statusarray.push(statusText == 'Pending' ? 0 : statusText == 'Processing' ? 1 : statusText == 'Completed' ? 2 : statusText == 'failed' ? 3 : '');
          })
          this.$store.dispatch("setLoader");
          await axios.get('all-withdrawal',{
                          params: {
                            emailIdSearch: this.WithdrawalFilterPopUp.emailIdSearch,
                            created_at_from: this.WithdrawalFilterPopUp.createdatFrom,
                            created_at_to: this.WithdrawalFilterPopUp.createdatTo,
                            updated_at_from: this.WithdrawalFilterPopUp.updatedatFrom,
                            updated_at_to: this.WithdrawalFilterPopUp.updatedatTo,
                            status: statusarray,
                            client_id:window.localStorage.CLIENT_ID

                          }
                        })
              .then(response => {
                console.log(response.data.data);
                if(response.data.status == true){
                  this.desserts = [];
                  var allWithdrawals = response.data.data;
                  allWithdrawals.forEach((withdrawal,index)=>{
                  this.desserts.push(
                      {
                          sino: index+1,
                          id: withdrawal.id,
                          requestid: withdrawal.request_id,
                          userid: withdrawal.user_email,
                          amount: withdrawal.currency + " " +withdrawal.amount,
                          createdat: withdrawal.created_at,
                          updatedat: withdrawal.updated_at,
                          status: withdrawal.status == 0 ? 'Pending' : withdrawal.status == 1 ? 'Processing' : withdrawal.status == 2 ? 'Completed' : 'Failed',
                          action: withdrawal.status == 0 ? 'Update': withdrawal.status == 1 ? 'Processing' : withdrawal.status == 2 ? 'Completed' : 'Failed',
                      },
                    );
                  });
                }
                else{
                  
                }
              });
              this.$store.dispatch("hideLoader");

        },
        approveItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogApprove = true
        this.item = item;
      },

      approveItemConfirm (item) {
        axios.post('change-withdrawal-status',{
            id:this.desserts[this.desserts.indexOf(item)].id,
        }).then(response => {
          if(response.status){
            this.desserts[this.desserts.indexOf(item)].action = "Completed";
            this.desserts[this.desserts.indexOf(item)].status = "Completed";
          }
        });
        // this.desserts.splice(this.editedIndex, 1)
        this.closeApprove()
      },


      closeApprove () {
        this.dialogApprove = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.item = '';
        })
      },
    },
})
</script>
<style>
.text-align-right{
  text-align: right;
}
</style>
