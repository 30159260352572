import axios from 'axios';

const ID_TOKEN_KEY = "CB_TOKEN";
const ID_ROLE_KEY = "CB_ROLE";
const CLIENT_ID = "CLIENT_ID";

// Function Get Token 
const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

// Function Get role 
const getRole = () => {
    return window.localStorage.getItem(ID_ROLE_KEY);
};

// Function Save Token 
const saveToken = (token) => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

// Function Save Client Id 
const saveClientId = (id) => {
    window.localStorage.setItem(CLIENT_ID, id);
};

// Function Save role 
const saveRole = (role) => {
    window.localStorage.setItem(ID_ROLE_KEY, role);
};

// Function Destroy Token 
const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
    // Request interceptors for API calls
    axios.interceptors.request.use(
        config => {
            config.headers['Authorization'] = null;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
};

// const destroyRole = () => {
//     window.localStorage.removeItem(ID_ROLE_KEY);
//     // Request interceptors for API calls
//     axios.interceptors.request.use(
//         config => {
//             config.headers['Authorization'] = null;
//             return config;
//         },
//         error => {
//             return Promise.reject(error);
//         }
//     );
// };

export default {
    getToken,
    saveToken,
    destroyToken,
    getRole,
    saveRole,
    saveClientId
};