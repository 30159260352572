import Vue from 'vue';
import axios from 'axios';
import JwtService from "@/core/services/jwt.service";
import router from '@/router.js';

const baseURL = "https://cb.admin.efocus.store/";
const SecondbaseURL = "http://integrare.efocus.store/"; // Base 2 URL

// creating instance.
const service = axios.create({
    baseURL,
    headers: {
        "Authorization": `Bearer ${JwtService.getToken()}`,
        // "Content-Type": 'multipart/form-data'
    }
})

const second_service = axios.create({
    baseURL: SecondbaseURL,
    // headers: {
    //     "Authorization": `Bearer ${JwtService.getToken()}`
    // }
})
/// **** ///


// Request interceptors for API calls
axios.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${JwtService.getToken()}`;
        // config.headers['Content-Type'] = 'multipart/form-data';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${JwtService.getToken()}`;
        return config;
    },
    error => {
        Vue.$toast.open({
            message: error.response.data.message || 'Error',
            type: 'error',
            duration: 3000,
            position: 'top-right',
            queue: true
        });
        return Promise.reject(error);
    }
);
// second_service.interceptors.request.use(
//     config => {
//         config.headers['Authorization'] = `Bearer ${JwtService.getToken()}`;
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );
/// **** ///

// Response interceptors
service.interceptors.response.use((response) => {
    if (response.data && response.data.status === 'Error') {
        Vue.$toast.open({
            message: response.data.message || 'Error',
            type: 'error',
            duration: 3000,
            position: 'top-right',
            queue: true
        });
    }
    return response
}, (error) => {
    if (!error.response.data.errors) {
        Vue.$toast.open({
            message: error.response.data.message || 'Error',
            type: 'error',
            duration: 3000,
            position: 'top-right',
            queue: true
        });
    } else if (error.response.data.errors) {
        for (const msg in error.response.data.errors) {
            console.log(`${error.response.data.errors[msg].toString()}`);
            Vue.$toast.open({
                message: error.response.data.errors[msg].toString(),
                type: 'error',
                duration: 3000,
                position: 'top-right',
                queue: true
            });
        }

    }
    if (error.response && error.response.status === 401) {
        if (router.history.current.fullPath != '/signIn') router.push('/signIn');
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

second_service.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response && error.response.status === 401) {
        router.push('/signIn');
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

/// **** ///

const setHeader = () => {
    service.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

    second_service.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
}

/**
   * Send the GET HTTP request
   * @param resource
   * @returns {*}
*/
const get = (resource) => {
    return service.get(resource).catch(error => {
        // check if token is expired
        if (error.response.status == 401) {
            throw new Error(`Token Expired: ${error}`);
        }
    });
}

/**
   * Send the POST HTTP request
   * @param resource
   * @param payload
   * @returns {*}
*/
const post = (resource, payload) => {
    return service.post(`${resource}`, payload).catch(error => {
        throw error;
    });
}


// Base2 URL requests.

/**
   * Send the GET HTTP request
   * @param resource
   * @returns {*}
*/
const get_base2 = (resource) => {
    return second_service.get(resource).catch(error => {
        // check if token is expired
        Vue.$toast.open({
            message: 'Error' || error.response.data.message,
            type: 'error',
            duration: 3000,
            position: 'top-right'
        });
        throw error;
    });
}

const Api = {
    get,
    post,
    setHeader,
    get_base2
}

export default Api;